import { useGetApiV1UserMe } from '@testsa-com/common/src/codegen/generated/user'
import { useTranslation } from 'react-i18next'
import { SocketContextProvider } from '../contexts/SocketContext'
import { MenuHelper } from '../helpers/Menu.helper'
import { Footer } from './Footer'
import { Head } from './Head'
import { Header } from './Header'

import styles from '../styles/layout/Layout.module.scss'

export const Layout = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation('common')
  const links = MenuHelper.getLinks(t)

  const meQuery = useGetApiV1UserMe()

  return (
    <SocketContextProvider>
      <Head title="Admin | TEST SA" description="TEST SA" />
      <Header links={links} user={meQuery.data?.body as any} />
      <main className={styles.main}>{children}</main>
      <Footer links={links} userType={meQuery.data?.body.type} />
    </SocketContextProvider>
  )
}
