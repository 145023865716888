import { getCookie, removeCookies, setCookie } from 'cookies-next'
import dayjs from 'dayjs'
import router from 'next/router'
import { AxiosInstance, type ErrorType } from '../codegen/AxiosInstance'
import { isPollsterApp } from './Env.helper'

export type TSAErrorType = ErrorType<{
  message: string
  content: { fieldError: string; fieldErrors: Record<string, { message: string }> }
}>

export const AuthHelper = {
  AUTH_CLIENT_TOKEN_NAME: isPollsterApp ? 'tsa-pollster-client-token' : 'tsa-client-token',
  AUTH_COOKIE_NAME: isPollsterApp ? 'tsa-pollster-token' : 'tsa-token',
  AUTH_COOKIE_OPTIONS: {
    secure: true,
    expires: dayjs().add(30, 'day').toDate(),
  },
  getErrorMessage: (error: TSAErrorType, customMessage?: string): string => {
    if (error) {
      const { status, data } = error?.response ?? {}
      const dataMessage = `Status ${status} | ${data?.message as string}`

      if (!status) {
        return error.message
      }

      if (data?.content?.fieldError) {
        return `${dataMessage}<br /><br />${customMessage ?? data?.content.fieldError}`
      }

      if (data?.content?.fieldErrors) {
        const fieldErrorsKeys = Object.keys(data?.content.fieldErrors)
        return `${dataMessage}<br /><br />${
          customMessage ??
          fieldErrorsKeys.map(fek => data?.content?.fieldErrors[fek]?.message).join(' / ')
        }`
      }

      return dataMessage
    }

    return 'Undefined error occured'
  },
  logout: () => {
    removeCookies(AuthHelper.AUTH_COOKIE_NAME)
    router.push('/login')
  },
}

export const initAxiosAuthInterceptor = () => {
  AxiosInstance.interceptors.response.use(
    config => {
      // Renew auth token if made available by the API
      if (config.headers['x-jwt-token']) {
        setCookie(
          AuthHelper.AUTH_COOKIE_NAME,
          config.headers['x-jwt-token'],
          AuthHelper.AUTH_COOKIE_OPTIONS,
        )
      }

      return config
    },
    error => {
      const paths = ['users', 'pollsters', 'projects']

      if (paths.some(p => router.pathname.includes(p)) && error?.response?.status === 404) {
        router.push('/404')

        return Promise.reject(error)
      }

      if (paths.some(p => router.pathname.includes(p)) && error?.response?.status === 403) {
        router.push('/')
      }

      return Promise.reject(error)
    },
  )

  AxiosInstance.interceptors.request.use(config => {
    if (config.headers) {
      // Add token if available
      const token = getCookie(AuthHelper.AUTH_COOKIE_NAME)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      } else {
        router.push('/login')
      }
    }

    return config
  })
}
