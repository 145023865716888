import React, { useEffect } from 'react'

import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import OneSignal from 'react-onesignal'

import { useGetApiV1UserMe } from '@testsa-com/common/src/codegen/generated/user'
import { useChatStore } from '../stores/useChatStore'

export const OneSignalSync = () => {
  const userMe = useGetApiV1UserMe()
  const { t } = useTranslation('pages')

  const [isLoaded, setIsLoaded] = React.useState(false)
  const isStartedRef = React.useRef(false)

  useEffect(() => {
    if (
      !isStartedRef.current &&
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID &&
      process.env.NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID
    ) {
      isStartedRef.current = true
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID,
        safari_web_id: process.env.NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID,
        notifyButton: {
          enable: true,
          position: 'bottom-right',
          text: {
            'tip.state.unsubscribed': t('field.onesignal.button.tip_state_unsubscribed'),
            'tip.state.subscribed': t('field.onesignal.button.tip_state_subscribed'),
            'tip.state.blocked': t('field.onesignal.button.tip_state_blocked'),
            'message.prenotify': t('field.onesignal.button.message_prenotify'),
            'message.action.subscribed': t('field.onesignal.button.message_action_subscribed'),
            'message.action.resubscribed': t('field.onesignal.button.message_action_resubscribed'),
            'message.action.unsubscribed': t('field.onesignal.button.message_action_unsubscribed'),
            'dialog.main.title': t('field.onesignal.button.dialog_main_title'),
            'dialog.main.button.subscribe': t(
              'field.onesignal.button.dialog_main_button_subscribe',
            ),
            'dialog.main.button.unsubscribe': t(
              'field.onesignal.button.dialog_main_button_unsubscribe',
            ),
            'dialog.blocked.title': t('field.onesignal.button.dialog_blocked_title'),
            'dialog.blocked.message': t('field.onesignal.button.dialog_blocked_message'),
          },
        },
        // only locally
        allowLocalhostAsSecureOrigin:
          (process.env.NEXT_PUBLIC_ENV ?? 'development') === 'development',
      })
        .then(() => {
          setIsLoaded(true)
        })
        .catch(e => {
          console.error(e)
          captureException(e)
        })
    }
  }, [t])

  useEffect(() => {
    if (userMe.data?.body.id && isLoaded) {
      OneSignal.login(userMe.data.body.id)
    }
  }, [isLoaded, userMe.data])

  const { isChatOpened } = useChatStore()

  return (
    <style key="style-bell">{`
    .onesignal-bell-launcher-button {
      display: ${isChatOpened ? 'block' : 'none'} !important;
    }
  `}</style>
  )
}
