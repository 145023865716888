/* eslint-disable no-console */
import React, { createContext, useContext } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { AuthHelper } from '@testsa-com/common/src/helpers/Auth.helper'
import { getCookie } from 'cookies-next'
import { type Socket, io } from 'socket.io-client'

type SocketContextType = {
  socket?: Socket
}
const SocketContext = createContext<SocketContextType>({})

export const useSocketContext = () => useContext(SocketContext)

let standaloneSocket:
  | {
      socket: Socket
      jwtToken: string
    }
  | undefined

export const SocketContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const queryClient = useQueryClient()

  const socket = React.useMemo(() => {
    const jwtToken = getCookie(AuthHelper.AUTH_COOKIE_NAME)
    if (jwtToken && standaloneSocket?.jwtToken === jwtToken) {
      return standaloneSocket.socket
    }
    if (standaloneSocket && standaloneSocket.jwtToken !== jwtToken) {
      standaloneSocket.socket.disconnect()
      standaloneSocket = undefined
    }
    if (!jwtToken || jwtToken === true) {
      return undefined
    }
    console.log('SOCKET connecting')
    const _socket = io(
      `${process.env.NEXT_PUBLIC_API_URL?.replace('https://', 'wss://')
        .replace('http://', 'ws://')
        // remove trailing slash
        .replace(/\/$/, '')}/socket/v1/chat`,
      {
        auth: { jwtToken },
        autoConnect: true,
        transports: ['websocket'],
      },
    )
    _socket.on('connect', () => {
      console.log('SOCKET connected')
    })

    _socket.on('disconnect', reason => {
      console.log('SOCKET disconnected', reason)
    })

    _socket.on('connect_error', err => {
      console.log('SOCKET connect_error', err)
    })

    standaloneSocket = {
      socket: _socket,
      jwtToken,
    }

    _socket.on('new-chat-message', () => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          if (Array.isArray(queryKey)) {
            return queryKey[0].startsWith('/api/v1/chat/')
          }

          return false
        },
      })
    })

    return _socket
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
