import { useEffect, useMemo, useState } from 'react'

import { MantineProvider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthHelper, initAxiosAuthInterceptor } from '@testsa-com/common/src/helpers/Auth.helper'
import { StylesHelper } from '@testsa-com/common/src/helpers/Styles.helper'
import { initI18n } from '@testsa-com/common/src/locales/config'
import { getCookie } from 'cookies-next'
import i18next from 'i18next'
import type { AppProps } from 'next/app'
import { OneSignalSync } from '../components/OneSignalSync'
import { Layout } from '../layout/Layout'
import { LoginLayout } from '../layout/LoginLayout'

import '../styles/globals.scss'

import '@blocknote/mantine/style.css'

initI18n()
initAxiosAuthInterceptor()
const queryClient = new QueryClient()

function MyApp({ Component, pageProps, router }: AppProps) {
  const [isPageReady, setIsPageReady] = useState(false)
  const isLogin = router.pathname.startsWith('/login')
  const isValidate = router.pathname.startsWith('/login/validate')
  const [clientToken] = useLocalStorage({ key: AuthHelper.AUTH_CLIENT_TOKEN_NAME })

  useEffect(() => {
    const cookie = getCookie(AuthHelper.AUTH_COOKIE_NAME) as string

    if ((!cookie && !isLogin) || (isValidate && !clientToken)) {
      router.push('/login')
    } else {
      setIsPageReady(true)
    }
  }, [router, isLogin, clientToken, isValidate])

  const LayoutComponent = useMemo(() => {
    if (isLogin) {
      return LoginLayout
    }

    return Layout
  }, [isLogin])

  if (isPageReady) {
    return (
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          withCSSVariables
          theme={{
            datesLocale: i18next.language,
            fontFamily: StylesHelper.defaultFont.style.fontFamily,
            headings: {
              fontFamily: StylesHelper.headingsFont.style.fontFamily,
            },
            primaryColor: 'violet',
            colors: {
              dark: StylesHelper.colors.dark,
              blue: StylesHelper.colors.blue,
              violet: StylesHelper.colors.violet,
              orange: StylesHelper.colors.orange,
            },
          }}
        >
          <NotificationsProvider>
            <ModalsProvider>
              {!isLogin && <OneSignalSync />}
              <LayoutComponent>
                <Component {...pageProps} />
              </LayoutComponent>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </QueryClientProvider>
    )
  }
}

export default MyApp
