import Axios, { AxiosError, AxiosRequestConfig, AxiosResponseHeaders } from 'axios'

export const AxiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
  },
})

type ResponseHeaders = AxiosResponseHeaders & {
  'content-length': string
  'content-type': string
  'x-total-count': number
}

export type TSAResponse<T> = { headers: ResponseHeaders, body: T }

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<TSAResponse<T>> => {
  const source = Axios.CancelToken.source()
  const promise = AxiosInstance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data, headers }) => {
    return {
      headers: headers as ResponseHeaders,
      body: data,
    }
  })

  // @ts-ignore Property 'cancel' does not exist on type 'Promise<any>'
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
